<template>
  <div>
    <PopupLanguage
      v-model="isPopupDuplicateActive"
      :hiddenLang="hiddenLang"
      :on-click-btn="handlerDuplicateContent"
      name="property-asset-project-create"
    />
    <div 
      id="data-list-list-view" 
      class="data-list-container">
      <custom-table
        ref="table"
        :max-items="itemsPerPage"
        :data="items"
        :searchKeys="searchKeys"
        pagination
        search
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <AddNewContent name="banner-create" />

          <!-- ITEMS PER PAGE -->
          <LhDropdownPerPage 
            :items="items" 
            :items-per-page="itemsPerPage" 
            :current-page="currentPage" 
            :queried-items="queriedItems"
            @click="(page) => itemsPerPage = page"
          /> 
        </div>

        <template slot="thead">
          <vs-th sort-key="url"><span>หน้าที่จะแสดง</span></vs-th>
          <vs-th sort-key="type">ประเภทชิ้นงาน</vs-th>
          <vs-th sort-key="start_date">วันที่เริ่มต้น</vs-th>
          <vs-th sort-key="end_date">วันที่สิ้นสุด</vs-th>
          <vs-th sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, index) in data" 
              :data="tr" 
              :key="index">
              <td class="td vs-table--td" @click.stop="editData(tr.revision_id)">{{ tr.url }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.revision_id)">{{ tr.type }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.revision_id)">{{
                tr.startDate ? publicDateFormat(tr.startDate) : 'ไม่มีกำหนด'
              }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.revision_id)">{{
                tr.endDate ? publicDateFormat(tr.endDate) : 'ไม่มีกำหนด'
              }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.revision_id)">
                <p class="brand-priority">
                  {{ updatedAtDatetimeFormat(tr.updated_at) }}
                </p>
              </td>
              <vs-td class="whitespace-no-wrap cursor-default">
                <div class="flex space-x-2">
                  <feather-icon
                    class="cursor-pointer"
                    icon="CopyIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="duplicateContent(tr.revision_id)"
                  />
                  <router-link
                    :to="{ name: `${routePrefix}-edit`, params: { id: tr.revision_id }}"
                    class="pt-2 color-text-link"
                  >
                    <feather-icon
                      icon="EditIcon"
                      svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    />
                  </router-link>
                  <feather-icon
                    icon="TrashIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    @click.stop="deleteData(tr.revision_id)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { computed, reactive, ref, watch } from '@vue/composition-api'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import last from 'lodash/last'
import orderBy from 'lodash/orderBy'
import omit from 'lodash/omit'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import useBannerUtil from '@/use/useBannerUtil'
import PopupLanguage from '@/components/PopupLanguage'
import AddNewContent from '@/components/AddNewContent'
import useNotify from '@/use/useNotify'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import CustomTable from '@/components/CustomTable'

export default {
  name: 'Index',
  components: {
    AddNewContent,
    PopupLanguage,
    LhDropdownPerPage,
    CustomTable
  },
  setup(props, ctx) {
    const { formData, prepareFormData, remainingPageList, existLangByUrl } = useBannerUtil(ctx)
    const crudFunction = useCrud(ctx, 'banner')
    const { moment, updatedAtDatetimeFormat } = useDatepicker()
    const notifyFunction = useNotify(ctx)
    const hiddenLang = ref([])

    crudFunction.routePrefix.value = 'banner'

    const items = computed(() => {
      return map(crudFunction.result.value, (item) => {
        let type = 'gallery'
        let startDate = null
        let endDate = null
        let startDates = []

        if (!isEmpty(item.custom_banner)) {
          const { custom_banner: banner } = item
          type =
            banner.event_type === 'video' ? banner.event_video_type : banner.event_type
          startDate = banner.event_start_date
          endDate = banner.event_end_date

          if (type === 'gallery' && !isEmpty(banner.event_desktop_banner)) {
            // startDate = sort(banner.event_desktop_banner, (v) ) 
            startDate = last(
              map(
                orderBy(banner.event_desktop_banner, ['image_start_date'], ['desc']),
                (v) => v.image_start_date
              )
            )

            endDate = last(
              map(
                orderBy(banner.event_desktop_banner, ['image_end_date'], ['asc']),
                (v) => v.image_end_date
              )
            )
          }
        }

        return {
          ...item,
          url: ctx.root.$t(item.page_url),
          type: ctx.root.$t(type),
          banner: item.custom_banner,
          startDate,
          startDates,
          endDate,
          updated_at: item.updated_at,
        }
      })
    })

    const handlerDuplicateContent = async (langToDuplicationContent) => {
      ctx.root.$vs.loading()
      await ctx.root.$store.dispatch('locale/changeLang', langToDuplicationContent)
      const result = await ctx.root.$store.dispatch(
        'banner/fetchOne',
        crudFunction.contentDuplicateId.value,
      )
      await prepareFormData(result)
      const activeUser = ctx.root.$store.state.AppActiveUser
      const email = get(activeUser, 'email', '')
      const created_by = email
      const updated_by = email

      const resultDuplicate = await ctx.root.$store.dispatch('banner/addItem', {
        ...omit(formData, ['updated_at']),
        lang: langToDuplicationContent,
        id: null,
        created_by,
        updated_by
      })
      crudFunction.isPopupDuplicateActive.value = false
      await ctx.root.$vs.loading.close()
      await ctx.root.$router.push({
        name: 'banner-edit',
        params: {
          id: resultDuplicate.revision_id,
        },
      })
    }

    const publicDateFormat = (value) => {
      return moment(value).format('DD MMM YYYY')
    }

    const duplicateContent = async (id) => {
      const result = await ctx.root.$store.dispatch(
        'banner/fetchOne',
        id,
      )

      await ctx.root.$store.dispatch(`banner/fetchAllLang`)

      const allItems = ctx.root.$store.state.banner.all_items

      const existLangByUrl = {}

      allItems.forEach((value) => {
        if (isEmpty(existLangByUrl[value.page_url])) existLangByUrl[value.page_url] = []
        existLangByUrl[value.page_url].push(value.lang)
      })

      const currentPageUrl = result.page_url
      const existLang = existLangByUrl[currentPageUrl]
      hiddenLang.value = existLang ? existLang : []
      crudFunction.duplicateContent(id)
    }

    return {
      ...crudFunction,
      duplicateContent,
      items,
      handlerDuplicateContent,
      publicDateFormat,
      updatedAtDatetimeFormat,
      hiddenLang,
      searchKeys: ['url', 'type', 'startDate', 'endDate']
    }
  },
}
</script>

<style scoped>
</style>
